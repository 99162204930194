import type { ParamMatcher } from "@sveltejs/kit";

/**
 * This exists to allow the creation of a survey experience that can be mounted in various parts of
 * the app.
 *
 * Currently, this is used for both admin users in `admin/surveys...` and regular users in
 * `detect-detox/surveys...`.
 */

export const match: ParamMatcher = (param) => {
  return param === "admin" || param === "detect-detox";
};
