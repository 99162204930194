import { PUBLIC_DEPLOY_ENV, PUBLIC_SENTRY_DISABLED } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";

if (PUBLIC_SENTRY_DISABLED !== "true") {
  Sentry.init({
    dsn: "https://7f6570917828021f8dea53dc2b778775@o1098001.ingest.sentry.io/4505744165699584",
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [Sentry.replayIntegration()],

    environment: PUBLIC_DEPLOY_ENV,
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
